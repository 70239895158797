
import { useStore } from 'vuex'
import { defineComponent } from 'vue'
import axios from 'axios'
import { Game } from '@/api'
import Markdown from './Markdown.vue'
import { ElMessageBox } from 'element-plus'

interface data {
  game: Game | undefined;
  description: string;
  installInprogress: boolean;
  installProgress: number;
  installSize: number;
}

export default defineComponent({
  name: 'viewport',
  components: {
    Markdown
  },
  data() {
    return {
      game: undefined,
      description: 'Loading ...',
      installInprogress: false,
      installProgress: 0,
      installSize: 1
    } as data
  },
  mounted(): void {
    const store = useStore()
    store.subscribe((mutation) => {
      if (mutation.type !== 'select') return
      this.game = store.state.games[mutation.payload - 1]
      console.log(this.game)
      axios.get(this.game?.description || '').then(res => {
        this.description = res.data
      })
    })
  },
  methods: {
    downloadLinux() {
      location.href = this.game?.downloads.linux || location.href
    },
    downloadWindows() {
      location.href = this.game?.downloads.windows || location.href
    },
    downloadLauncherWindows() {
      location.href = 'https://github.com/VBPO-spongia-hry/VBPO-Spongia-hry.github.io/releases/download/GameLauncher/game-launcher.Setup.0.1.0.exe'
    },
    downloadLauncherLinux() {
      location.href = 'https://github.com/VBPO-spongia-hry/VBPO-Spongia-hry.github.io/releases/download/GameLauncher/game-launcher-0.1.0.AppImage'
    }
  }
})

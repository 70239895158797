import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_viewport = _resolveComponent("viewport")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, null, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_aside, { width: "300px" }, {
            default: _withCtx(() => [
              _createVNode(_component_sidebar)
            ]),
            _: 1
          }),
          _createVNode(_component_el_main, {
            class: "main",
            style: {"padding":"0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_viewport)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}

import { ElLoading } from 'element-plus'
import { FetchAllGameData, Game } from '@/api'
import { defineComponent } from 'vue-demi'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      Games: Array<Game>(),
      $store: useStore()
    }
  },
  created ():void {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    FetchAllGameData().then((e) => {
      this.Games = e
      this.$store.commit('setGames', e)
      loading.close()
    })
  },
  methods: {
    handleOpen (key: number) {
      this.$store.commit('select', key)
    }
  }
})

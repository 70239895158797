
import Viewport from './components/Viewport.vue'
import Sidebar from './components/Sidebar.vue'
// import Settings from './components/Settings.vue'
import { useStore } from 'vuex'
import { defineComponent } from 'vue'

interface data {
  title: string;
}

export default defineComponent({
  components: {
    Viewport,
    Sidebar
    // Settings
  },
  data() {
    return {
      title: 'Game launcher'
    } as data
  },
  mounted(): void {
    document.title = '!.+? hry'
    const store = useStore()
    store.subscribe((mutation) => {
      if (mutation.type !== 'select') return
      this.title = store.state.games[mutation.payload - 1]?.name || 'Game launcher'
    })
  }
})
